.grouptable-active {
    background: var(--darker-background-color) !important;
}

.grouptable-title {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.grouptable-input {
    margin-right: auto;
}

@media only screen and (max-width: 800px) {
    .grouptable-title>div {
        margin-left: 0;
    }
}