.disabled-btn {
    color: #fff;
    margin: .5em !important;
    padding: .8em !important;
    cursor: default;
    background-color: var(--main-orange);
    opacity: 0.3;
}

.icon-btn {
    border-radius: .3em;
    padding: .3em;
    margin: .5em;
    display: flex;
    justify-content: relative;
    cursor: pointer;
    user-select: none;
}

.icon-btn-text {
    padding-left: .3em;
}

.clear-orange-btn {
    color: var(--main-orange);
    padding: .5em !important;
    padding-top: .5em !important;
    padding-bottom: .5em !important;
    justify-content: center;
}

.clear-orange-btn-mock {
    color: var(--main-orange);
    padding: .5em !important;
    padding-top: .5em !important;
    padding-bottom: .5em !important;
    width: fit-content;
}

.clear-orange-btn:hover {
    background-color: var(--main-orange);
    color: #fff;
}