.grid-header {
    grid-area: header;
    background-color: #fff;
    color: #444;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-logo {
    flex: 4;
    display: flex;
}

.logo {
    max-height: 4em;
    padding-left: 2em;
}

.header-business {
    flex: 2;
}

.header-user {
    flex: 3;
}

.user-panel {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    user-select: none;
}

.nav-stack-btn {
    padding: 2em;
}

.grid-menu {
    grid-area: menubar;
    background-color: #444;
    color: white;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

/* MEDIA  */

.mobile-screen {
    display: none;
}

/* WIDE SCREEN */

@media only screen and (min-width: 1190px) {
    .grid-segment {
        display: grid !important;
        grid-template-columns: auto 70vw auto !important;
        grid-template-areas: ". segment ." !important;
    }

    .segment {
        grid-area: segment;
    }
}

/* MEDIUM SCREEN */

@media only screen and (max-width:1190px) {
    .grid-segment {
        display: grid;
        grid-template-columns: auto;
        grid-template-areas: "segment"
    }

    .segment {
        grid-area: segment;
    }


    .mobile-screen {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .mobile-screen {
        display: flex;
    }

    .grid-menu {
        height: auto;
    }

    .navbar {
        display: none !important;
        height: 0;
    }

    .navbar.nav-active {
        display: flex !important;
        flex-direction: column !important;
        height: auto;
    }
}

@media only screen and (max-width: 600px) {
    .header-user {
        flex: 1;
    }
}