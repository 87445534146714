.notification {
    position: fixed;
    left: 50%;
    font-size: medium;
    z-index: 1100 !important;
    text-align: center;
    top: 0;
    padding: 1em;
    width: 100%;
    min-height: .5em;
    animation: slide 15100ms;
}

.notification-btn {
    position: fixed;
    color: #313233;
    right: 0;
    z-index: 1101;
    top: 0.5em;
}

.success {
    background-color: #70aa6d;
    color: #e8e8e8;
}

.failure {
    background-color: #f6653c;
    color: #e8e8e8;
}

.warning {
    background-color: #edc974;
    color: #313233;
}

@keyframes slide {
    0% {
        transform: translate(-50%, -200%);
    }

    15% {
        transform: translate(-50%, 0%);
    }

    85% {
        transform: translate(-50%, 0%);
    }

    100% {
        transform: translate(-50%, -200%);
    }
}