.order-desc {
    width: 100%;
}

.order-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.order-info-item {
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    align-items: center !important;
}